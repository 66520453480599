.homeVisitsPage .newVisitBtnCont {
  margin: 50px 0 0 0;
  text-align: center;
}
.homeVisitsPage .newVisitBtnCont a {
  background: #359ee5;
  color: white;
  padding: 10px 30px;
  border-radius: 10px;
  text-decoration: none;
}

.homeVisitsPage .newVisitBtnCont a:hover {
  background: #2f4155;
}

.HomeVisitsNewVisit .insertNewVisit {
  width: 80%;
  margin: 40px auto 0;
}

.HomeVisitsNewVisit .insertNewVisit .selectorContent {
  position: relative;
  z-index: 10;
}

.HomeVisitsNewVisit .insertNewVisit .selectorContent .formContainer > div {
  margin-bottom: 10px;
}

.HomeVisitsNewVisit .insertNewVisit .selectorContent .SearchAutocomplete {
  margin: -5px 0 0 0;
}

.ReportHomeVisit {
  margin: 0px 0 0 0;
}

.ReportHomeVisit h2 {
  font-size: 30px;
}

.ReportHomeVisit input:disabled {
  background: white;
}

.ReportHomeVisit .formCont {
  margin: 20px 0 0 0;
}

/* .ReportHomeVisit .formCont .row {
  margin-bottom: 20px;
} */

.ReportHomeVisit .formCont .row .divBoostrap {
  margin-bottom: 20px;
}

.ReportHomeVisit .formCont section {
  margin: 0 0 40px 0;
}

.ReportHomeVisit .formCont section h3 {
  font-size: 20px;
  margin: 0 0 10px 0;
}

.ReportHomeSaveData .sectionForm {
  background: white;
  padding: 20px 30px;
  box-shadow: 0px 4px 5px -1px rgb(0 0 0 / 20%);
  margin: 0 0 40px 0;
}

.ReportHomeSaveData .sectionForm h2 {
  font-family: 'fb_regular', 'helvetica', 'arial' !important;
  margin: 0 0 20px 0;
}

.ReportHomeSaveData .sectionForm h2 small {
  font-size: 20px;
}

.ReportHomeSaveData .sectionForm header {
  margin: 0 0 5px 0;
}

.ReportHomeSaveData .sectionForm header h3 {
  font-size: 16px;
  margin: 0;
  display: inline-block;
  font-weight: bold;
}

.ReportHomeSaveData .sectionForm header p {
  display: inline-block;
  font-size: 15px;
  margin: 0 10px 0px 0;
}

.ReportHomeSaveData .sendBtn {
  position: fixed;
  left: 30px;
  bottom: 30px;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  font-size: 20px;
}

.ReportHomeSaveData .sectionForm .line {
  margin: 0 0 30px 0;
}

.ReportHomeSaveData p.smallText {
  font-size: 18px;
  margin: 15px 0 50px 0;
}

.ReportHomeSaveData .lastSec h2 {
  font-size: 20px;
  font-family: 'fb_regular', 'helvetica', 'arial' !important;

  margin: 0 0 40px 0;
}

.ReportHomeSaveData .lastSec .contLast .line {
  float: right;
  width: 200px;
}

.ReportHomeSaveData .lastSec .contLast .signCont {
  float: right;
  margin: 0 20px 0 0;
}

.ReportHomeSaveData .lastSec .contLast .signCont h3 {
  font-size: 20px;
  font-family: 'fb_regular', 'helvetica', 'arial' !important;
  text-align: center;
}

.ReportHomeSaveData .lastSec .contLast .signCont button {
  padding: 0;
  margin: 0;
  border: none;
  color: red;
  background: transparent;
  font-size: 18px;
}

.ReportHomeSaveData .lastSec .goBackLink {
  text-align: left;
  padding: 0 0 0 120px;
}

.ReportHomeSaveData .lastSec .goBackLink a {
  text-decoration: none;
  background: #2f4155;
  padding: 5px 15px;
  font-size: 14px;
  color: white;
  border-radius: 10px;
}

.ReportHomeSaveData .lastSec .goBackLink a:hover {
  background: black;
}

.HomeVisitsNewVisit.print .site-menu,
.HomeVisitsNewVisit.print .NavInner {
  display: none;
}

.HomeVisitsNewVisit.print {
  background: white;
}

.HomeVisitsNewVisit.print .printCont {
  width: 1000px;
  margin: 0 auto;
}

.HomeVisitsNewVisit.print .printCont .lastSec .goBackLink,
.HomeVisitsNewVisit.print .sendBtn {
  display: none;
}
